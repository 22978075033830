<template>
  <div>
    <head-section pageName="Continue Extraction"></head-section>
    <loader v-if="loading"></loader>
    <v-row style="margin-top: 0.5%" v-if="!loading">
      <v-col cols="5">
        <form-preview
          style="margin-left: 30px"
          :coordinates="coordinates"
          overlay="overlay"
          :showImage="showImage"
          :tableData="tableData"
          :previewImage="previewImage"
          :loading="loading"
        ></form-preview>
      </v-col>
      <v-col cols="7">
        <div class="outer-box" v-if="!loading">
          <div class="table-heading">
            <v-row
              ><v-col cols="10" style="margin-top: 0.8%"
                ><div>Form Identifiers</div></v-col
              ><v-col cols="2">
                <v-btn @click="addRow()" tile elevation="0"
                  >Add Row</v-btn
                ></v-col
              ></v-row
            >
          </div>
          <v-data-table
            :headers="headers"
            fixed-header
            height="527"
            :items="itemsWithSno"
            :items-per-page="10"
            style="
              border-left: #e0e0e0 solid 1px;
              border-right: #e0e0e0 solid 1px;
              border-bottom: #e0e0e0 solid 1px;
            "
          >
            <template slot="item" slot-scope="props">
              <tr
                @mouseover="getBoundingBox(props.item)"
                @mouseleave="overlay = false"
              >
                <td>{{ props.item.sno }}</td>
                <td>{{ props.item.target_var }} <span v-if="props.item.required" class="red--text">*</span></td>
                <td
                  class="table-input-container"
                  @dblclick.stop="saveEditData(props.item, 'edit')"
                >
                  <div style="position: relative">
                    <input
                      v-if="
                        props.item.value == 'SELECTED' ||
                        props.item.value == 'NOT SELECTED'
                      "
                      type="checkbox"
                      style="
                        border: 1px solid grey;
                        border-style: inset;
                        padding: 5px;
                      "
                      :class="
                        props.item.validate && props.item.confidence >= 90
                          ? 'dark--green'
                          : props.item.validate && props.item.confidence < 90
                          ? 'orange--color'
                          : !props.item.validate
                          ? 'red--color'
                          : ''
                      "
                      :checked="props.item.value == 'SELECTED' ? true : false"
                      @input="onInput($event, props.item.ID, 'checkbox')"
                      @change="checkEnabled(props.item.ID)"
                    />
                    <input
                      v-else-if="props.item.enableField"
                      type="text"
                      style="
                        border: 1px solid grey;
                        border-style: inset;
                        padding: 5px;
                      "
                      :value="props.item.value"
                      @input="onInput($event, props.item.ID, 'input')"
                    />
                    <div v-else-if="props.item.suggestions.length != 0">
                      <p
                        v-for="(p, index) in props.item.value"
                        class="m--0"
                        :class="
                          props.item.validate &&
                          props.item.confidence >= 90 &&
                          props.item.suggestions[index]?.length == 0
                            ? 'dark--green'
                            : props.item.validate &&
                              props.item.confidence < 90 &&
                              props.item.suggestions[index]?.length == 0
                            ? 'orange--color'
                            : !props.item.validate ||
                              props.item.suggestions[index]?.length > 0
                            ? 'red--color'
                            : 'red--color'
                        "
                        style="display: inline-block; position: relative"
                        :key="index"
                        @click="
                          openSuggestion(
                            props.item.suggestions[index],
                            index,
                            p
                          )
                        "
                        :style="
                          props.item.value.length - 1 != index
                            ? 'margin-right:10px !important;'
                            : 'margin-right:0px'
                        "
                      >
                        <span
                          :class="
                            props.item.suggestions[index]?.length > 0
                              ? 'incorrect-word-color'
                              : 'normal-word-color'
                          "
                          :id="'incorrectWord' + index"
                          style="cursor: pointer"
                        >
                          {{ p }}</span
                        >
                      </p>
                      <div
                        v-if="wordClick && suggestions.length"
                        class="elevation-4"
                        :style="
                          suggestionIndex > 0 ? 'left: 65px' : 'left:0px;'
                        "
                        style="
                          top: 25px;
                          background-color: white;
                          z-index: 2;
                          position: absolute;
                          padding: 3%;
                          border-radius: 5%;
                        "
                      >
                        <ul
                          style="
                            list-style-type: none;
                            padding-right: 40px;
                            padding-left: 12px;
                          "
                        >
                          <li
                            v-for="(item, ind) in suggestions"
                            :key="ind"
                            style="cursor: pointer"
                            @click="replaceWord(item, suggestionIndex)"
                          >
                            {{ item
                            }}<v-icon
                              size="14"
                              v-if="item == incorrectWord"
                              title="This is extracted text (Worng Medical Term)"
                              >mdi-exclamation</v-icon
                            >
                          </li>
                          <li>
                            <v-icon
                              size="15"
                              class="mr-5"
                              @click="wordClick = false"
                              >mdi-cancel</v-icon
                            >
                            <v-icon size="15" @click="addword(props.item)"
                              >mdi-tray-plus</v-icon
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p
                      v-else
                      :class="
                        props.item.validate && props.item.confidence >= 90
                          ? 'dark--green'
                          : props.item.validate && props.item.confidence < 90
                          ? 'orange--color'
                          : !props.item.validate
                          ? 'red--color'
                          : ''
                      "
                      class="m--0"
                    >
                      {{ props.item.value }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="vertical--align">
                    <!-- <v-icon
                      class="mr-2"
                      medium
                      @click="saveEditData(props.item, 'edit')"
                      >mdi-pencil-outline</v-icon
                    > -->
                    <v-icon
                      class="mr-2"
                      medium
                      @click="saveEditData(props.item, 'tick')"
                      >mdi-check</v-icon
                    >
                    <v-tooltip bottom v-if="!props.item.validate">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on" color="red"
                          >mdi-alert-outline</v-icon
                        >
                      </template>
                      <span>{{ props.item.description }}</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:footer.page-text class="text-right">
              <v-btn
                color="danger"
                class="mr-3"
                @click="onCancel()"
                tile
                elevation="0"
              >
                Cancel
              </v-btn>
              <v-btn
                tile
                elevation="0"
                color="primary"
                @click="onSubmit()"
                :disabled="submitBtn"
              >
                Submit
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <dialog-box
          v-if="openDialog"
          :open-dialog="openDialog"
          :table-length="tableLength"
          @close-dialog="closeDialog"
          @save-data="saveData"
        />
      </v-col>
    </v-row>
    <!-- <v-dialog v-model="spellCheck" width="100"> -->

    <!-- </v-dialog> -->
  </div>
</template>
<script>
import HeadSection from "../components/HeadSection";
import FormPreview from "../components/FormPreview";
import DialogBox from "../components/DialogBox.vue";
import Loader from "../components/Loader.vue";
export default {
  components: {
    HeadSection,
    FormPreview,
    DialogBox,
    Loader,
  },
  data: () => ({
    headers: [
      { text: "S. No.", value: "sno", width: "12%" },
      { text: "Required Keys", value: "target_var", width: "33%" },
      // { text: "Extracted Keys", value: "key", width: "30%" },
      { text: "Value", value: "value", width: "40%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "30%",
      },
      ,
    ],
    tableData: [],
    coordinates: {},
    previewImage: null,
    showImage: false,
    changeImage: "",
    isDataUpdated: false,
    openDialog: false,
    enableField: false,
    onSubmitVar: false,
    loading: false,
    mappedDataset: [],
    submitBtn: true,
    wordClick: false,
    items: [{ word: "apple" }, { word: "cherry" }, { word: "mango" }],
    suggestions: [],
    suggestionIndex: 0,
    incorrectWord: "",
    cancelEnable: false,
    // isFormDirty: false,
  }),

  computed: {
    itemsWithSno() {
      this.tableData = this.tableData.map((item, index) => {
        item.sno = index + 1;
        return item;
      });
      return this.tableData;
    },
  },
  mounted() {
    if (this.$store.state.isFormDirty) {
      window.onbeforeunload = function () {
        return "Are you sure you want to close the window?";
      };
    }
    this.callOnMounted();
    this.getData();
  },
  methods: {
    checkEnabled(id) {
      let index = this.tableData.findIndex((item) => {
        return item.ID == id;
      });
      this.tableData[index].enableField =
        this.tableData[index].value == "SELECTED";
    },
    openSuggestion(suggestion, inde, item) {
      this.wordClick = true;
      this.suggestionIndex = inde;
      // this.incorrectWord = item.incorrect_word;
      this.suggestions = suggestion;
    },
    checkSubmitBtn() {
      let flag = 0;
      this.tableData.forEach((e) => {
        if (e.validate == false) {
          flag = 1;
        }
      });
      if (flag == 1) {
        this.submitBtn = true;
      } else {
        this.submitBtn = false;
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    async onCancel() {
      this.cancelEnable = true;
      if (this.$store.state.isFormDirty) {
        await this.$router.push("/form-queue");
        this.$store.getters.queueType = "Unassigned";
      } else {
        await this.$router.push("/form-queue");
        this.$store.getters.queueType = "Unassigned";
      }
    },
    async saveEditData(item, action) {
      let params = {};
      this.$store.state.isFormDirty = true;
      if (action == "tick") {
        item.confidence = 100;
        if (item.enableField) {
          params.id = this.$route.params.id;
          let response = await this.axios.post(
            process.env.VUE_APP_END_POINT + "/validate-regex",
            item,
            {
              params,
              headers: {
                accessToken: sessionStorage.accessToken,
              },
            }
          );
          item.validate = response.data.data.validate;
          item.value = response.data.data.value;
          item.suggestions = response.data.data.suggestions;
          this.tableData = this.tableData.map((dataItem) => {
            if (dataItem.ID === item.ID) {
              if (dataItem.suggestions.length > 0) {
                dataItem.suggestions = item.suggestions;
                dataItem.value = item.value;
                dataItem.enableField = false;
                this.checkSubmitBtn();
              } else if (dataItem.value === "SELECTED") {
                dataItem.validate = true;
                this.checkSubmitBtn();
              } else {
                dataItem.enableField = false;
                dataItem.validate = item.validate;
                dataItem.value = item.value.toString();
                dataItem.suggestions = item.suggestions;
                this.checkSubmitBtn();
              }
            }
            return dataItem;
          });
        } else if (!item.enableField) {
          this.tableData.forEach((e) => {
            if (e.ID == item.ID) {
              e.confidence = 100;
              e.KeyConfidence = 100;
              e.validate = true;
              e.value = item.value.toString();
              e.suggestions = [];
              this.checkSubmitBtn();
            }
          });
        }
      } else if (!(item.value == "SELECTED" || item.value == "NOT SELECTED")) {
        item.enableField = true;
      }
    },
    async addword(item) {
      let params = {};
      this.$store.state.isFormDirty = true;
      params.word = item.value[this.suggestionIndex];
      params.label = item.label[0][0];
      let response = await this.axios.post(
        process.env.VUE_APP_END_POINT + "/add-word",
        params,
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        this.wordClick = false;
        if (response.data.data == "Already Present")
          alert("Word already present!");
        else alert("Word added!");
      }
    },
    getBoundingBox(item) {
      let Left = item.BoxLeft ? item.BoxLeft * 600 + 30 : item.Left * 600 + 30;
      let Top = item.BoxTop ? item.BoxTop * 610 + 102 : item.Top * 610 + 102;
      let Width = item.BoxWidth ? item.BoxWidth * 620 : item.Width * 620;
      let Height = item.BoxHeight ? item.BoxHeight * 702 : item.Height * 702;
      this.coordinates = {
        left: Left,
        top: Top,
        width: Width,
        height: Height,
      };
      this.changeImage = item.File_Name;
      this.change_image(this.changeImage);
      this.overlay = true;
    },
    change_image(image) {
      if (!image) return;
      let arr = image.split("s");
      arr = arr[1].split(".");
      let index = arr[0];
      this.previewImage = parseInt(index);
    },
    onInput(e, id, type) {
      this.isDataUpdated = true;
      // this.isFormDirty = true;
      this.$store.state.isFormDirty = true;
      let index = this.tableData.findIndex((i) => i.ID == id);

      if (type == "checkbox") {
        if (e.target.checked) this.tableData[index].value = "SELECTED";
        else this.tableData[index].value = "NOT SELECTED";
      } else if (type == "key") {
        this.tableData[index].key = e.target.value;
        this.tableData[index].enableField = true;
      } else {
        this.tableData[index].value = e.target.value;
        this.tableData[index].enableField = true;
      }
    },
    addRow() {
      this.openDialog = true;
      this.tableLength = this.tableData.length;
    },
    saveData(data) {
      this.tableData.push(data);
      this.isDataUpdated = true;
    },
    async onSubmit() {
      this.loading = true;
      this.onSubmitVar = true;
      this.$store.state.isFormDirty = false;
      this.$store.state.submit = true;
      const params = {};
      params.id = this.$route.params.id;
      params.extracted_by = JSON.parse(
        sessionStorage.getItem("account")
      ).localAccountId;
      let result = {};
      this.tableData.forEach((item) => {
        result[item.target_var] = item.value;
      });
      let response = await this.axios.post(
        process.env.VUE_APP_END_POINT + "/extraction-page",
        result,
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        if (response.data.data == "Done") {
          this.loading = false;
          this.$store.getters.queueType = "Submitted";
          this.$router.push("/form-queue");
          this.$store.state.submit = false;
        }
      }
    },
    async getData() {
      this.loading = true;
      const params = {};
      params.id = this.$route.params.id;
      this.$store.getters.form_id = this.$route.params.id;
      params.currently_assigned = 3;
      params.user = JSON.parse(
        sessionStorage.getItem("account")
      ).localAccountId;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/extraction-page",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        this.showImage = true;
        let res = response.data.data;
        this.mappedDataset = response.data.mapped_data;
        res.forEach((item) => {
          item.enableField = false;
        });
        
        this.tableData = [...res];

        this.tableData = this.tableData.map((q) => ({
          ...q,
          required: false,
        }));

        this.tableData.forEach((item) => {
          this.mappedDataset.forEach((e) => {
            if (item.target_var == e.target_var) {
              item.required = e.required;
              const index = this.mappedDataset.indexOf(e);
              this.mappedDataset.splice(index, 1);
            }
          });
        });
        this.tableData.forEach((item) => {
          if (item.value == "" || item.value == []) {
            item.value = "Null";
          }
          if (item.key == "") {
            item.key = "Not Extracted";
          }
        });

        this.mappedDataset.forEach((item) => {
          this.tableData.push({
            target_var: item.target_var,
            ID: item.ID,
            key: "",
            value: "",
            description: item.description,
            validate: !item.required,
            enableField: false,
            required: item.required,
            suggestions: [],
          });
        });
        this.checkSubmitBtn();
        this.loading = false;
      }
    },
    replaceWord(word, i) {
      this.$store.state.isFormDirty = true;
      this.suggestions.push(
        document.getElementById("incorrectWord" + i).innerHTML
      );
      let removeInd = this.suggestions.indexOf(word);
      this.suggestions.splice(removeInd, 1);
      this.wordClick = false;
      this.tableData = this.tableData.map((item) => {
        let id = this.tableData.indexOf(item);
        if (typeof item.value == "object") {
          this.tableData[id].value.splice(
            this.tableData[id].value.indexOf(this.tableData[id].value[i]),
            1,
            word
          );
        }
        return item;
      });
      return this.tableData;
    },
    signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
    callOnMounted() {
      const params = {};
      params.id = this.$route.params.id;
      params.currently_assigned = 2;
      params.user = "Not Assigned";
      let url = process.env.VUE_APP_END_POINT + "/extraction-page";
      this.$store.state.url = url;
      this.$store.state.params = params;
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
* {
  font-family: "Gilroy-Light";
}
.outer-box {
  margin-left: 3%;
  margin-top: -0.4px;
  width: 95%;
}
.table-heading {
  display: flex;
  color: #fff;
  font-family: "Gilroy-Light";
  font-weight: 800;
  padding-top: 1%;
  padding-left: 2%;
  background-color: #1773bc;
  width: 100%;
  font-size: 1.2em;
  height: 50px;
}
.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="checkbox"]:before {
  content: close-quote;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-bottom: 4px;
  margin-left: 1px;
  border-radius: 2px;
}
.vertical--align {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}
.dark--green::before {
  outline: 2px solid #408913 !important;
}
.orange--color::before {
  outline: 2px solid rgb(255, 119, 0);
}
.dark--green {
  color: #408913 !important;
}
.orange--color {
  color: rgb(255, 119, 0);
}
.red--color {
  color: #ff0000 !important;
}
.red--color::before {
  outline: 3px solid #ff0000;
}
.tickIcon {
  width: 15px;
}
.m--0 {
  margin: 0px !important;
}
.mr--5 {
  margin-right: 20px !important;
}
input:focus-visible {
  outline: none !important;
}
.table-input-container {
  input:not([disabled]):not([type="checkbox"]) {
    outline: 2px solid black !important;
  }
}
.incorrect-word-color {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #ff0000;
}
.normal-word-color {
  font-size: inherit;
}
</style>
