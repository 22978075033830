<template>
  <div>
    <head-section pageName="Form Discovery"></head-section>
    <loader v-if="loading"></loader>
    <v-row class="mx--18 mt--14">
      <v-col cols="5">
        <form-preview
          :coordinates="coordinates"
          overlay="overlay"
          :showImage="showImage"
          :tableData="tableData"
          :previewImage="previewImage"
          :loading="loading"
        ></form-preview>
      </v-col>
      <v-col cols="7">
        <div class="outer-box" v-if="!loading">
          <div class="table-heading">Form Identifiers</div>
          <v-data-table
            :headers="headers"
            fixed-header
            height="545"
            :items="itemsWithSno"
            :items-per-page="10"
            style="
              border-left: #e0e0e0 solid 1px;
              border-right: #e0e0e0 solid 1px;
              border-bottom: #e0e0e0 solid 1px;
            "
          >
            <template slot="item" slot-scope="props">
              <tr
                @mouseover="getBoundingBox(props.item)"
                @mouseleave="overlay = false"
              >
                <td>{{ props.item.sno }}</td>
                <td>{{ props.item.Data }}</td>
                <td>
                  <v-checkbox
                    color="success"
                    :value="props.item.checked"
                    @change="onChecked(props.item)"
                  ></v-checkbox>
                </td>
              </tr>
            </template>
            <template v-slot:footer.page-text>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    class="mr-3"
                    @click="onCancel"
                    tile
                    elevation="0"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    tile
                    elevation="0"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="oncheckboxsubmit"
                    :disabled="submitDisable"
                  >
                    Submit
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Choose Form Name
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row justify="center" class="ma-2">
                        <v-col sm="6">
                          <v-text-field
                            v-if="isInputShow"
                            append-icon="mdi-window-close"
                            @click:append="closeInput"
                            v-model="formName"
                            outlined
                            :rules="requiredRules"
                          >
                            <template #label>
                              Form Template Name
                              <span class="red--text"><strong>* </strong></span>
                            </template></v-text-field
                          >
                          <v-select
                            outlined
                            v-else
                            item-text="formName"
                            v-model="formName"
                            @change="setFormPages"
                            :rules="requiredRules"
                            :items="formNameOption"
                          >
                            <template #label>
                              Form Template Name
                              <span class="red--text"><strong>* </strong></span>
                            </template></v-select
                          >
                        </v-col>
                      </v-row>
                      <v-row justify="center" class="ma-2">
                        <v-col sm="6">
                          <v-text-field
                            v-model="formPages"
                            outlined
                            :rules="requiredRules"
                            :readonly="!isInputShow"
                          >
                            <template #label>
                              Number of pages
                              <span class="red--text"><strong>* </strong></span>
                            </template></v-text-field
                          >
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="onsubmitform"
                      tile
                      elevation="0"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeadSection from "../components/HeadSection";
import FormPreview from "../components/FormPreview";
import Loader from "../components/Loader.vue";

export default {
  components: {
    HeadSection,
    FormPreview,
    Loader,
  },
  data: () => ({
    valid: false,
    requiredRules: [(v) => !!v || ""],
    headers: [
      { text: "S. No.", value: "sno", width: "20%" },
      {
        text: "Key",
        value: "Data",
        sortable: false,
        align: "start",
        width: "60%",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
        width: "20%",
      },
    ],
    tableData: [],
    dialog: false,
    submitDisable: true,
    formNameOption: [],
    coordinates: {},
    selectedItem: [],
    formName: "",
    previewImage: null,
    showImage: false,
    loading: true,
    changeImage: "",
    isInputShow: false,
    formPages: null,
    cancelEnable: false,
    isFormDirty: false,
    cancelform: false,
  }),
  computed: {
    itemsWithSno() {
      return this.tableData.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  methods: {
    closeInput() {
      this.isInputShow = false;
    },
    onChecked(item) {
      item.checked = !item.checked;
      if (item.checked) {
        this.selectedItem.push({
          id: item.id,
          Data: item.Data,
          checked: item.checked,
        });
      }
      this.selectedItem.forEach((i) => {
        if (item.checked == false) {
          if (i.id == item.id) {
            const ind = this.selectedItem.indexOf(i);
            this.selectedItem.splice(ind, 1);
          }
        }
      });
      if (!this.selectedItem.length) {
        this.submitDisable = true;
      } else {
        this.submitDisable = false;
        this.$store.state.isFormDirty = true;
      }
    },
    getBoundingBox(item) {
      let Left = item.BoxLeft ? item.BoxLeft * 600 + 30 : item.Left * 600 + 30;
      let Top = item.BoxTop ? item.BoxTop * 610 + 110 : item.Top * 610 + 110;
      let Width = item.BoxWidth ? item.BoxWidth * 620 : item.Width * 620;
      let Height = item.BoxHeight ? item.BoxHeight * 710 : item.Height * 710;
      this.coordinates = {
        left: Left,
        top: Top,
        width: Width,
        height: Height,
      };
      this.changeImage = item.File_Name;
      this.change_image(this.changeImage);
      this.overlay = true;
    },
    change_image(image) {
      let arr = image.split("m");
      arr = arr[1].split(".");
      let index = arr[0];
      this.previewImage = parseInt(index);
    },
    async getData() {
      const params = {};
      params.id = this.$route.params.id;
      params.currently_assigned = 1;
      params.user = JSON.parse(
        sessionStorage.getItem("account")
      ).localAccountId;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/form-discovery",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        let res = Object.values(response.data);
        let data = JSON.parse(res[0]);
        data = data.data;
        data = data.map((q) => ({
          ...q,

          checked: false,
        }));
        this.tableData = [...data];
        if (this.tableData.length != 0) {
          this.showImage = true;
          this.loading = false;
        }
      }
    },

    async oncheckboxsubmit() {
      this.formNameOption = [];
      this.formPages = null;
      let params = {};
      params.route = "discovery";
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/form-templates",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        let res = Object.values(response.data);
        let tempData = res[0];
        tempData.forEach((item) => {
          this.formNameOption.push({
            pageCount: item.count,
            formName: item.formName,
          });
        });
        this.formNameOption.push("Add Custom");
      }
    },
    setFormPages() {
      this.formNameOption.forEach((e) => {
        if (e.formName == this.formName) this.formPages = e.pageCount;
      });
    },
    async onsubmitform() {
      this.$refs.form.validate();
      this.$store.state.isFormDirty = false;
      this.$store.state.submit = true;
      if (this.formName && this.formPages) {
        this.dialog = false;
        this.loading = true;
        const params = {};
        params.id = this.$route.params.id;
        params.currently_assigned = 1;
        if (this.isInputShow) params.count = this.formPages;
        params.processed_by = JSON.parse(
          sessionStorage.getItem("account")
        ).localAccountId;
        this.selectedItem.push({ formName: this.formName });
        let response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/form-discovery",
          JSON.stringify(this.selectedItem),
          {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
        let res = response.data.data[0];
        if (res.data == "True") {
          this.loading = false;
          this.$router.push("/form-templates");
          this.$store.state.submit = false;
        }
      }
      this.$refs.form.resetValidation();
    },
    // async cancel() {
    //   this.loading = true;
    //   const params = {};
    //   params.id = this.$route.params.id;
    //   params.currently_assigned = 0;
    //   params.user = "Not Processed";
    //   let response = await this.axios.get(
    //     process.env.VUE_APP_END_POINT + "/form-discovery",
    //     {
    //       params,
    //       headers: {
    //         accessToken: sessionStorage.accessToken,
    //       },
    //     }
    //   );
    //   if (response) {
    //     this.loading = false;
    //     this.$router.push("/form-queue");
    //     this.$store.getters.queueType = "Template Review";
    //   }
    // },
    async onCancel() {
      this.cancelEnable = true;
      if (this.$store.state.isFormDirty) {
        await this.$router.push("/form-queue");
        this.$store.getters.queueType = "Review";
      } else {
        await this.$router.push("/form-queue");
        this.$store.getters.queueType = "Review";
      }
    },
    callOnMounted() {
      const params = {};
      params.id = this.$route.params.id;
      params.currently_assigned = 0;
      params.user = "Not Processed";
      let url = process.env.VUE_APP_END_POINT + "/form-discovery";
      this.$store.state.url = url;
      this.$store.state.params = params;
    },
  },
  watch: {
    formName() {
      if (this.formName == "Add Custom") {
        this.formPages = null;
        this.isInputShow = true;
        this.formName = "";
      }
    },
  },
  // async beforeRouteLeave(to, from, next) {
  //   if (this.isFormDirty && !this.cancelEnable) {
  //     swal({
  //       title: "Discard Changes",
  //       text: "You have some unsaved changes. Do you want to discard changes?",
  //       icon: "info",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         this.cancel();
  //         next();
  //       } else {
  //         next(false);
  //       }
  //     });
  //   } else {
  //     if (!this.cancelEnable) {
  //       await this.cancel();
  //       next();
  //     }
  //   }
  // },
  beforeMount() {
    // this.verifyUser();
  },
  // async beforeDestroy() {
  //   if (this.submitDisable && !this.cancelEnable) {
  //     this.cancel();
  //   }
  // },
  mounted() {
    if (this.$store.state.isFormDirty) {
      window.onbeforeunload = function () {
        return "Are you sure you want to close the window?";
      };
    }
    this.callOnMounted();
    this.getData();
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
* {
  font-family: "Gilroy-Light";
}
.outer-box {
  margin-left: 3%;
  margin-top: -0.4px;
}
.table-heading {
  display: flex;
  color: #fff;
  font-family: "Gilroy-Light";
  font-weight: 800;
  padding-top: 1%;
  padding-left: 2%;
  background-color: #1773bc;
  width: 100%;
  font-size: 1.2em;
  height: 30px;
}
.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
