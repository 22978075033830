<template>
  <div>
    <head-section pageName="Create Dataset"></head-section>
    <loader v-if="loading"></loader>
    <div v-else>
      <v-form ref="datasetform" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row class="main-field ml-2">
            <v-col cols="3">
              <v-text-field
                v-model="datasetName"
                type="text"
                outlined
                :rules="requiredRules"
              >
                <template #label>
                  Dataset Name
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field></v-col
            >
            <v-spacer></v-spacer>
            <v-col
                  cols="2"
                  style="
                    justify-content: flex-end;
                    display: flex;
                    margin-right: 1.3%;
                  "
                >
            <v-btn
                    color="primary"
                    @click="addField"
                    v-bind="attrs"
                    v-on="on"
                    tile
                    elevation="0"
                    >+ Add Fields</v-btn
                  >
            </v-col>
          </v-row>
          <v-row class="table-row">
            <v-col cols="12">
              <v-data-table
                fixed-header
                height="55vh"
                style="border: 1px #bdbdbd solid"
                ref="tableset"
                :headers="headers"
                :items="itemsWithSno"
                :items-per-page="10"
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="props.item.required"
                        :value="props.item.required"
                        @change="setCheckBox(props.item.sno - 1, $event)"
                      ></v-checkbox>
                    </td>
                    <td>{{ props.item.sno }}</td>
                    <td>{{ props.item.field_name }}</td>
                    <td>{{ props.item.datatype }}</td>
                    <td>{{ props.item.format }}</td>
                    <td>
                      <span class="mr-8">
                        <v-tooltip bottom light>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              medium
                              @click="editRule(props.item, index)"
                              >mdi-square-edit-outline</v-icon
                            >
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip bottom light>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              medium
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteRule(props.item)"
                              >mdi-trash-can-outline</v-icon
                            >
                          </template>
                          <span>Discard</span>
                        </v-tooltip>
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-row style="justify-content: flex-end; margin-right: 2%">
          <v-btn
            color="danger"
            @click="onCancel"
            class="mr-5"
            tile
            elevation="0"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            @click="onSubmit"
            tile
            :disabled="submitDisable"
            elevation="0"
            >Submit</v-btn
          >
        </v-row>
      </v-form>
      <v-dialog v-model="openForm" max-width="500px">
      <v-card>
                <v-card-title>
                  <span class="text-h5" v-if="!edit">Add New Field</span>
                  <span class="text-h5" v-else>Edit Field</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="text"
                            v-model="fieldName"
                            outlined
                            :rules="requiredRules"
                          >
                            <template #label>
                              Field Name
                              <span class="red--text"><strong>* </strong></span>
                            </template></v-text-field
                          >
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            outlined
                            v-model="datatype"
                            :items="datatypes"
                            :rules="requiredRules"
                          >
                            <template #label>
                              Datatype
                              <span class="red--text"><strong>* </strong></span>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            outlined
                            v-model="formatName"
                            :items="formatNames"
                            :rules="requiredRules"
                          >
                            <template #label>
                              Format
                              <span class="red--text"><strong>* </strong></span>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveField">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import HeadSection from "../components/HeadSection.vue";
import Loader from "../components/Loader.vue";
export default {
  components: {
    HeadSection,
    Loader,
  },
  computed: {
    itemsWithSno() {
      return this.tableData.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  data: () => ({
    openForm: false,
    valid: false,
    datasetform: false,
    tableset: false,
    requiredRules: [(v) => !!v || ""],
    pageName: "",
    loading: false,
    headers: [
      { text: "Required", value: "required" },
      { text: "S. No.", value: "sno" },
      {
        text: "Field Name",
        value: "field_name",
        sortable: false,
        align: "start",
      },
      {
        text: "Datatype",
        value: "datatype",
        sortable: false,
        align: "start",
      },
      {
        text: "Format",
        value: "format",
        sortable: false,
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
      },
    ],
    formatNames: [],
    datatypes: [],
    tableData: [],
    datasetName: "",
    fieldName: "",
    datatype: "",
    formatName: "",
    dialog: false,
    dialogEdit: false,
    edit: "",
    cancelEnable: false,
    submitDisable: true,
    submitclicked: false,
  }),
  methods: {
    onCancel() {
      this.cancelEnable = true;
      if (!this.datasetName == "" || this.tableData.length) {
        swal({
          title: "Discard Changes",
          text: "You have some unsaved changes. Do you want to discard changes?",
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.cancel();
          }
        });
      } else {
        this.cancel();
      }
    },
    setCheckBox(index, value) {
      if(this.$route.params.id) this.submitDisable = false;
      this.tableData[index].required = value;
    },
    cancel() {
      this.loading = true;
      this.tableData = [];
      this.datasetName = "";
      this.loading = false;
      if(this.cancelEnable){
      this.$store.getters.settingtype = "manageDataset";
        this.$router.back();
      }
    },
    async onSubmit() {
      this.$refs.datasetform.validate();
      this.submitclicked = true;
      this.tableData.forEach((e) => {
        if (e.required == null) e.required = false;
      });
      if (this.datasetName && this.tableData.length) {
        this.loading = true;
        let params = {};
        if (!this.$route.params.id == "") {
          params.submittype = "edit";
          params.id = this.$route.params.id;
        } else params.submittype = "new";
        params.dataset_name = this.datasetName;
        let response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/create-dataset",
          this.tableData,
          {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
        if (response.data.data == "Created") {
          this.loading = false;
          this.$store.getters.settingtype = "manageDataset";
          this.$router.back();
        }
      }
      this.$refs.datasetform.resetValidation();
    },
    async getFormatName() {
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/metadata-list",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        if (response.data.status_code == 200) {
          let data = response.data.data;
          data.forEach((e) => {
            this.formatNames.push(e.field_name);
          });
          this.datatypes = response.data.datatypes;
        }
      }
    },
    addField() {
      this.openForm=true;
      this.formatNames = [];
      this.datatypes = [];
      this.getFormatName();
    },
    editRule(item) {
      this.edit = item.sno;
      this.openForm=true;
      this.formatNames = [];
      this.datatypes = [];
      this.getFormatName();
      this.fieldName = item.field_name;
      this.datatype = item.datatype;
      this.formatName = item.format;
    },
    deleteRule(item) {
      this.tableData.splice(item.sno - 1, 1);
    },
    saveField() {
      this.$refs.form.validate();
      if (this.fieldName && this.formatName && this.datatype) {
        if (this.edit != "") {
          this.tableData[this.edit - 1].field_name = this.fieldName;
          this.tableData[this.edit - 1].datatype = this.datatype;
          this.tableData[this.edit - 1].format = this.formatName;
          this.edit = "";
        } else
          this.tableData.unshift({
            required: false,
            field_name: this.fieldName,
            datatype: this.datatype,
            format: this.formatName,
          });
        this.close();
      }
      if (this.datasetName != "" && this.tableData.length != 0) {
        this.submitDisable = false;
      }
      this.$refs.form.resetValidation();
    },
    close() {
      this.edit = "";
      this.openForm = false;
      this.dialogEdit = false;
      this.fieldName = "";
      this.datatype = "";
      this.formatName = "";
    },
    async checkEdit() {
      if (!this.$route.params.id == "") {
        let params = {};
        params.id = this.$route.params.id;
        let response = await this.axios.get(
          process.env.VUE_APP_END_POINT + "/create-dataset",
          {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
        if (response.data == "User Error") {
          alert("User Error! PLease login again");
          this.signOut();
        } else {
          this.datasetName = response.data.dataset_name;
          this.tableData = response.data.data;
        }
      }
    },
    signOut() {
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
  mounted() {
    this.checkEdit();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.cancelEnable && !this.submitclicked) {
      if (this.datasetName != "" || this.tableData.length) {
        swal({
          title: "Discard Changes",
          text: "You have some unsaved changes. Do you want to discard changes?",
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.cancel();
            next();
            
          }
        });
      } else {
        this.cancel();
        next();
      }
    }
    else{
      next();
    }
  },
};
</script>
<style>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
.v-data-table.v-data-table--fixed-header.theme--light.v-data-table.v-data-table--fixed-header
  thead
  th {
  background-color: #f4f5f8 !important;
  font-family: "Gilroy-Extra Bold" !important;
  font-weight: bolder;
}
.main-field {
  margin-top: 0.5%;
  margin-left: 1%;
}
.text-field-row {
  margin-top: 1.1%;
  margin-left: 1%;
  margin-right: 1%;
}
.table-row {
  margin: 1% 0.5%;
}
</style>
