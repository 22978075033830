var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('head-section',{attrs:{"pageName":"Form Templates"}})],1),(_vm.loading)?_c('loader'):_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"tagAlign",staticStyle:{"margin-top":"20px","border":"#e0e0e0 solid 1px"},attrs:{"page":_vm.pageNo,"server-items-length":_vm.totalCount,"items-per-page":_vm.itemsOnPage,"headers":_vm.headers,"height":"80vh","items":_vm.tableData},on:{"update:page":[_vm.changeInPage,function($event){_vm.pageNo=$event}],"update:items-per-page":_vm.changeInItems},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',[_c('div',{staticStyle:{"color":"#1773bc"}},[_vm._v(_vm._s(props.item.formName))])]),_c('td',[_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(_vm._s(props.item.datetime))])]),_c('td',[_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(_vm._s(props.item.count))])]),_c('td',[_c('div',{staticClass:"actionsFormTemplates"},[(!props.item.mapped_data.length)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',_vm._g({class:{ actionIcon: props.item.enable }},
                          props.item.enable
                            ? {
                                click: () =>
                                  _vm.mapFunction(props.item.vform_draft_id.$oid),
                              }
                            : {}
                        ),[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Map")])])],1):_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.editTemplate(props.item)}}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Mapping")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-switch',{model:{value:(props.item.enable),callback:function ($$v) {_vm.$set(props.item, "enable", $$v)},expression:"props.item.enable"}})],1)]}}],null,true)},[_c('span',[_vm._v("Enable/Disable")])])],1)])])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }