<template>
  <div v-if="showPage">
    <head-section pageName="Settings"></head-section>
    <v-row class="mt--14 ml-1 mr-2">
      <v-col cols="9">
        <v-tabs class="ml-4" v-model="activeTab">
          <v-tab @click="getManageUsers" style="text-transform: capitalize"
            >Manage Users</v-tab
          >
          <v-tab @click="getManageDataset" style="text-transform: capitalize"
            >Manage Dataset</v-tab
          >
          <v-tab @click="getMetaDataList" style="text-transform: capitalize"
            >Metadata List</v-tab
          >
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="2"
        class="mt-2 mr-2"
        style="display: flex; justify-content: right"
      >
        <v-btn
          v-if="showManageUsers"
          class="primary"
          @click="addNewUser"
          tile
          elevation="0"
          >+ Add New User</v-btn
        >
        <v-btn
          v-if="showManageDataset"
          class="primary"
          @click="$router.push('/create-dataset-template')"
          tile
          elevation="0"
          >+ Add New Dataset</v-btn
        >
        <v-btn
          v-if="showMetaDataList"
          class="primary"
          @click="addNewRule"
          tile
          elevation="0"
          >+ Add New Rule</v-btn
        >
      </v-col>
    </v-row>
    <manage-dataset v-if="showManageDataset"></manage-dataset>
    <meta-data-list
      v-if="showMetaDataList"
      :showAddMetaData="showAddMetaData"
      @close="close"
    ></meta-data-list>
    <manage-users
      v-if="showManageUsers"
      :showForm="showForm"
      @close="close"
    ></manage-users>
  </div>
</template>
<script>
import HeadSection from "../components/HeadSection.vue";
import ManageUsers from "../components/ManageUsers.vue";
import MetaDataList from "../components/MetaDataList.vue";
import ManageDataset from "../components/ManageDataset.vue";

export default {
  components: {
    HeadSection,
    ManageDataset,
    ManageUsers,
    MetaDataList,
  },
  data: () => ({
    activeTab: 0,
    showPage: false,
    showMetaDataList: false,
    showManageUsers: true,
    showManageDataset: false,
    showForm: false,
    showAddForm: false,
    showAddMetaData: false,
  }),
  methods: {
    getManageUsers() {
      this.$router.push({ name: "settings", params: { id: "0" } });
      this.activeTab= 0;
      this.showManageUsers = true;
      this.showMetaDataList = false;
      this.showManageDataset = false;
    },
    getManageDataset() {
      this.$router.push({ name: "settings", params: { id: "1" } });
      this.activeTab = 1;
      this.showManageUsers = false;
      this.showMetaDataList = false;
      this.showManageDataset = true;
    },
    getMetaDataList() {
      this.$router.push({ name: "settings", params: { id: "2" } });
      this.activeTab = 2;
      this.showManageUsers = false;
      this.showMetaDataList = true;
      this.showManageDataset = false;
    },
    addNewUser() {
      this.showForm = true;
    },
    addNewRule() {
      this.showAddMetaData = true;
    },
    close(val) {
      this.showForm = false;
      this.showAddMetaData = false;
    },
  },
  mounted() {
    this.showPage = true;
    if(this.$route.params.id == 2){
      this.getMetaDataList();
    }
    else if(this.$route.params.id == 1){
      this.getManageDataset();
    }
    else{
      this.getManageUsers();
    }
  },
};
</script>
<style scoped>
.main-box {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}
.outer-card {
  margin-top: 2%;
  width: 80%;
  padding: 2%;
}
.inner-card {
  margin-top: 2%;
}
.inner-card :hover {
  background-color: #6c6b6b;
  color: white;
}
.inner-card-title {
  margin-left: 2%;
}
.sign-out-btn {
  color: white;
  padding: 9%;
  width: 90%;
  height: 80%;
  font-size: 0.85em;
}
</style>
