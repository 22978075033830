import { render, staticRenderFns } from "./FormQueue.vue?vue&type=template&id=bab987f0&scoped=true&"
import script from "./FormQueue.vue?vue&type=script&lang=js&"
export * from "./FormQueue.vue?vue&type=script&lang=js&"
import style0 from "../assets/styles/css/form-queue.css?vue&type=style&index=0&id=bab987f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab987f0",
  null
  
)

export default component.exports