var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('head-section',{attrs:{"pageName":"Form Queue"}}),_c('v-container',{staticClass:"mx--18 mt--14",staticStyle:{"width":"98%"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-tabs',{staticClass:"font-Medium font--16",model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},[_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getintakeQueue}},[_vm._v("Intake")]),(_vm.roleType)?_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getTemplateReview}},[_vm._v("Template Review")]):_vm._e(),_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getFormReviewUnassigned}},[_vm._v("Unassigned")]),_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getFormReviewAssigned}},[_vm._v("Assigned ")]),_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getSubmittedQueue}},[_vm._v("Submitted ")]),_c('v-tab',{staticClass:"queue_tab",on:{"click":_vm.getIllegibleQueue}},[_vm._v("Illegible")])],1)],1),_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"py-5 font-Bold",attrs:{"disabled":!_vm.isIntakeQueue,"color":"primary","tile":"","elevation":"0"},on:{"click":_vm.oninput}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-tray-arrow-up")]),_vm._v("   Upload ")],1),_c('input',{ref:"uploadFile",staticClass:"d-none",attrs:{"type":"file","multiple":""},on:{"change":_vm.onUpload}})],1)],1),_c('v-row',{staticClass:"remove_top_space"},[_c('v-col',{staticClass:"remove_top_space",attrs:{"cols":"showpreview?8:12"}},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.computedHeaders,"page":_vm.pageNo,"server-items-length":_vm.totalCount,"loading":_vm.loader,"fixed-header":"","height":"78vh","items":_vm.itemsWithFormSno,"items-per-page":_vm.itemsOnPage,"footer-props":{
            'items-per-page-options': [10, 20, 30, -1],
          }},on:{"update:page":[_vm.changeInPage,function($event){_vm.pageNo=$event}],"update:items-per-page":_vm.changeInItems},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"font-Medium"},[_c('td',[_vm._v(_vm._s(props.item.sno))]),_c('td',[_c('div',{staticStyle:{"color":"#1773bc","cursor":"pointer"},attrs:{"title":"Download File"},on:{"click":function($event){return _vm.downloadFile(props.item.file_url)}}},[_vm._v(" "+_vm._s(props.item.formname)+" ")])]),_c('td',[_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(_vm._s(props.item.datetime))])]),(_vm.isIntakeQueue)?_c('td',[_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(_vm._s(props.item.uploaded_by))])]):_vm._e(),(!_vm.isIntakeQueue && !_vm.isUnassignedFormReview)?_c('td',[(_vm.isTemplateReview)?_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(" "+_vm._s(props.item.processed_by)+" ")]):_vm._e(),(_vm.isAssignedFormReview || _vm.isSubmittedQueue)?_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(" "+_vm._s(props.item.extracted_by)+" ")]):_vm._e(),(_vm.isIllegibleQueue)?_c('div',[_vm._v(" "+_vm._s(props.item.completed_by)+" ")]):_vm._e()]):_vm._e(),(!_vm.isIllegibleQueue)?_c('td',[_vm._v(_vm._s(props.item.source))]):_vm._e(),(!_vm.isTemplateReview && !_vm.isIllegibleQueue)?_c('td',[(props.item.is_processed)?_c('div',{staticStyle:{"color":"#6e6f74"}},[_vm._v(" "+_vm._s(props.item.assigned_template)+" ")]):_c('div',{staticStyle:{"color":"#ff3838"}},[_vm._v("Not Associated")])]):_vm._e(),(_vm.isSubmittedQueue)?_c('td',[_vm._v(_vm._s(props.item.target_dataset))]):_vm._e(),(!_vm.isSubmittedQueue && !_vm.isIllegibleQueue)?_c('td',[(props.item.is_extracted == 0 || props.item.isloading)?_c('div',[_c('pulse-loader')],1):_vm._e(),(props.item.is_processed == 3)?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#6ad28d","background-color":"#e6fbf9"}},[_vm._v(" Updated to GRX ")]):_vm._e(),(_vm.isTemplateReview || _vm.isIntakeQueue)?_c('div',[(
                      props.item.is_processed == 0 &&
                      props.item.currently_assigned == 0 &&
                      props.item.is_extracted == 1
                    )?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#f6ac41","background-color":"#fef5e7"}},[_vm._v(" Pending Template Review ")]):_vm._e(),(
                      props.item.is_processed == 1 &&
                      props.item.currently_assigned == 1
                    )?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#f6ac41","background-color":"#fef5e7"}},[_vm._v(" Pending Dataset Mapping ")]):_vm._e(),(
                      (props.item.is_processed == 0 &&
                        props.item.currently_assigned == 1) ||
                      (props.item.is_processed == 1 &&
                        props.item.currently_assigned == 2)
                    )?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#08a2e2","background-color":"#e5f7ff"}},[_vm._v(" Processing... ")]):_vm._e()]):_vm._e(),(
                    _vm.isAssignedFormReview ||
                    _vm.isUnassignedFormReview ||
                    _vm.isIntakeQueue
                  )?_c('div',[(
                      props.item.is_processed == 2 &&
                      props.item.currently_assigned == 2
                    )?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#6e6f74","background-color":"#f4f4f4"}},[_vm._v(" Not Assigned ")]):_vm._e(),(
                      props.item.is_processed == 2 &&
                      props.item.currently_assigned == 3
                    )?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#08a2e2","background-color":"#e5f7ff"}},[_vm._v(" Processing... ")]):_vm._e(),(props.item.is_processed == 4)?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#ff3838","background-color":"#fdeaee"}},[_vm._v(" Unable to Extract ")]):_vm._e(),(props.item.is_processed == 5)?_c('div',{staticClass:"font-Medium font--14 staus-box",staticStyle:{"color":"#6ad28d","background-color":"#e6fbf9"}},[_vm._v(" Completed ")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.isIllegibleQueue)?_c('td',[(props.item.is_processed == 4)?_c('v-btn',{staticClass:"font-Medium font--14",staticStyle:{"color":"#1773bc","text-transform":"capitalize"},attrs:{"depressed":"","color":"#E5F7FF"},on:{"click":function($event){return _vm.processingForm(props.item)}}},[_vm._v("Process "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1):_c('v-btn',{staticClass:"font-Medium font--14",staticStyle:{"color":"#408913","text-transform":"capitalize"},attrs:{"depressed":"","color":"#E6FBF9"}},[_vm._v("Completed "),_c('v-icon',[_vm._v("mdi-check")])],1)],1):_vm._e(),_c('td',{staticClass:"actionBtn"},[(_vm.isTemplateReview && props.item.is_processed == 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"disabled":props.item.is_processed == 0 &&
                          props.item.currently_assigned == 1},on:{"click":function($event){return _vm.sendFormId(props.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-text-box-search-outline")])]}}],null,true)},[_c('span',[_vm._v("Form Discovery")])])],1):_vm._e(),(_vm.isTemplateReview && props.item.is_processed == 1)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"disabled":props.item.is_processed == 1 &&
                          props.item.currently_assigned == 2},on:{"click":function($event){return _vm.$router.push('/form-templates')}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document-multiple-outline")])]}}],null,true)},[_c('span',[_vm._v("Form Templates")])])],1):_vm._e(),(_vm.isUnassignedFormReview)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",on:{"click":function($event){return _vm.formExtract(props.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-cog-outline")])]}}],null,true)},[_c('span',[_vm._v("Continue Extraction")])])],1):_vm._e(),(_vm.isIntakeQueue || _vm.isIllegibleQueue)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"disabled":!(
                            props.item.is_processed == 0 ||
                            props.item.is_processed == 4
                          ) || !props.item.is_extracted},on:{"click":function($event){return _vm.onDelete(props.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can-outline")])]}}],null,true)},[_c('span',[_vm._v("Discard")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":!props.item.is_extracted},on:{"click":function($event){return _vm.showPDFPreview(props.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-outline")])]}}],null,true)},[_c('span',[_vm._v("Preview")])])],1)])]}}])})],1),(_vm.showpreview)?_c('v-col',{staticClass:"remove_top_space",attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"preview_card"},[_c('v-spacer'),_c('span',{staticStyle:{"margin-top":"-7px"}},[_vm._v("Preview")]),_c('v-spacer'),_c('v-icon',{staticStyle:{"margin-right":"2%","margin-top":"-7px"},attrs:{"color":"white","size":"20"},on:{"click":function($event){_vm.showpreview = false}}},[_vm._v("mdi-window-close")])],1),_c('v-carousel',{attrs:{"height":"78vh"}},_vm._l((_vm.img_url),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"image-item",attrs:{"src":item}})}),1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }