<template>
  <div>
    <head-section pageName="Form Queue"></head-section>
    <v-container fluid class="mx--18 mt--14" style="width: 98%">
      <v-row>
        <v-col cols="10">
          <v-tabs v-model="active_tab" class="font-Medium font--16">
            <v-tab @click="getintakeQueue" class="queue_tab">Intake</v-tab>
            <v-tab v-if="roleType" @click="getTemplateReview" class="queue_tab"
              >Template Review</v-tab
            >
            <v-tab @click="getFormReviewUnassigned" class="queue_tab"
              >Unassigned</v-tab
            >
            <v-tab @click="getFormReviewAssigned" class="queue_tab"
              >Assigned
            </v-tab>
            <v-tab @click="getSubmittedQueue" class="queue_tab"
              >Submitted
            </v-tab>
            <v-tab @click="getIllegibleQueue" class="queue_tab"
              >Illegible</v-tab
            >
          </v-tabs>
        </v-col>
        <v-col cols="2" style="display: flex; justify-content: flex-end">
          <v-btn
            :disabled="!isIntakeQueue"
            color="primary"
            @click="oninput"
            class="py-5 font-Bold"
            tile
            elevation="0"
          >
            <v-icon small>mdi-tray-arrow-up</v-icon> &nbsp; Upload
          </v-btn>
          <input
            ref="uploadFile"
            type="file"
            @change="onUpload"
            multiple
            class="d-none"
          />
        </v-col>
      </v-row>
      <v-row class="remove_top_space">
        <v-col class="remove_top_space" cols="showpreview?8:12">
          <v-data-table
            :headers="computedHeaders"
            @update:page="changeInPage"
            @update:items-per-page="changeInItems"
            :page.sync="pageNo"
            :server-items-length="totalCount"
            :loading="loader"
            fixed-header
            height="78vh"
            :items="itemsWithFormSno"
            :items-per-page="itemsOnPage"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, -1],
            }"
            class="data-table"
          >
            <template slot="item" slot-scope="props">
              <tr class="font-Medium">
                <td>{{ props.item.sno }}</td>
                <td>
                  <div
                    @click="downloadFile(props.item.file_url)"
                    style="color: #1773bc; cursor: pointer"
                    title="Download File"
                  >
                    {{ props.item.formname }}
                  </div>
                </td>
                <td>
                  <div style="color: #6e6f74">{{ props.item.datetime }}</div>
                </td>
                <td v-if="isIntakeQueue">
                  <div style="color: #6e6f74">{{ props.item.uploaded_by }}</div>
                </td>
                <td v-if="!isIntakeQueue && !isUnassignedFormReview">
                  <div v-if="isTemplateReview" style="color: #6e6f74">
                    {{ props.item.processed_by }}
                  </div>
                  <div
                    v-if="isAssignedFormReview || isSubmittedQueue"
                    style="color: #6e6f74"
                  >
                    {{ props.item.extracted_by }}
                  </div>
                  <div v-if="isIllegibleQueue">
                    {{ props.item.completed_by }}
                  </div>
                </td>
                <td v-if="!isIllegibleQueue">{{ props.item.source }}</td>
                <td v-if="!isTemplateReview && !isIllegibleQueue">
                  <div style="color: #6e6f74" v-if="props.item.is_processed">
                    {{ props.item.assigned_template }}
                  </div>
                  <div style="color: #ff3838" v-else>Not Associated</div>
                </td>
                <td v-if="isSubmittedQueue">{{ props.item.target_dataset }}</td>
                <td v-if="!isSubmittedQueue && !isIllegibleQueue">
                  <div
                    v-if="props.item.is_extracted == 0 || props.item.isloading"
                  >
                    <pulse-loader></pulse-loader>
                  </div>
                  <div
                    class="font-Medium font--14 staus-box"
                    style="color: #6ad28d; background-color: #e6fbf9"
                    v-if="props.item.is_processed == 3"
                  >
                    Updated to GRX
                  </div>
                  <div v-if="isTemplateReview || isIntakeQueue">
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #f6ac41; background-color: #fef5e7"
                      v-if="
                        props.item.is_processed == 0 &&
                        props.item.currently_assigned == 0 &&
                        props.item.is_extracted == 1
                      "
                    >
                      Pending Template Review
                    </div>
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #f6ac41; background-color: #fef5e7"
                      v-if="
                        props.item.is_processed == 1 &&
                        props.item.currently_assigned == 1
                      "
                    >
                      Pending Dataset Mapping
                    </div>
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #08a2e2; background-color: #e5f7ff"
                      v-if="
                        (props.item.is_processed == 0 &&
                          props.item.currently_assigned == 1) ||
                        (props.item.is_processed == 1 &&
                          props.item.currently_assigned == 2)
                      "
                    >
                      Processing...
                    </div>
                  </div>
                  <div
                    v-if="
                      isAssignedFormReview ||
                      isUnassignedFormReview ||
                      isIntakeQueue
                    "
                  >
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #6e6f74; background-color: #f4f4f4"
                      v-if="
                        props.item.is_processed == 2 &&
                        props.item.currently_assigned == 2
                      "
                    >
                      Not Assigned
                    </div>
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #08a2e2; background-color: #e5f7ff"
                      v-if="
                        props.item.is_processed == 2 &&
                        props.item.currently_assigned == 3
                      "
                    >
                      Processing...
                    </div>
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #ff3838; background-color: #fdeaee"
                      v-if="props.item.is_processed == 4"
                    >
                      Unable to Extract
                    </div>
                    <div
                      class="font-Medium font--14 staus-box"
                      style="color: #6ad28d; background-color: #e6fbf9"
                      v-if="props.item.is_processed == 5"
                    >
                      Completed
                    </div>
                  </div>
                </td>
                <td v-if="isIllegibleQueue">
                  <v-btn
                    v-if="props.item.is_processed == 4"
                    depressed
                    color="#E5F7FF"
                    style="color: #1773bc; text-transform: capitalize"
                    class="font-Medium font--14"
                    @click="processingForm(props.item)"
                    >Process <v-icon>mdi-arrow-right</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    depressed
                    color="#E6FBF9"
                    style="color: #408913; text-transform: capitalize"
                    class="font-Medium font--14"
                    >Completed <v-icon>mdi-check</v-icon></v-btn
                  >
                </td>
                <td class="actionBtn">
                  <span v-if="isTemplateReview && props.item.is_processed == 0">
                    <v-tooltip bottom light>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-4"
                          v-bind="attrs"
                          :disabled="
                            props.item.is_processed == 0 &&
                            props.item.currently_assigned == 1
                          "
                          v-on="on"
                          @click="sendFormId(props.item)"
                          >mdi-text-box-search-outline</v-icon
                        >
                      </template>
                      <span>Form Discovery</span>
                    </v-tooltip>
                  </span>
                  <span v-if="isTemplateReview && props.item.is_processed == 1">
                    <v-tooltip bottom light>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-4"
                          v-bind="attrs"
                          :disabled="
                            props.item.is_processed == 1 &&
                            props.item.currently_assigned == 2
                          "
                          v-on="on"
                          @click="$router.push('/form-templates')"
                          >mdi-file-document-multiple-outline</v-icon
                        >
                      </template>
                      <span>Form Templates</span>
                    </v-tooltip>
                  </span>
                  <span v-if="isUnassignedFormReview">
                    <v-tooltip bottom light>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="formExtract(props.item)"
                          >mdi-file-cog-outline</v-icon
                        >
                      </template>
                      <span>Continue Extraction</span>
                    </v-tooltip>
                  </span>
                  <span v-if="isIntakeQueue || isIllegibleQueue">
                    <v-tooltip bottom light>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :disabled="
                            !(
                              props.item.is_processed == 0 ||
                              props.item.is_processed == 4
                            ) || !props.item.is_extracted
                          "
                          class="mr-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="onDelete(props.item)"
                          >mdi-trash-can-outline</v-icon
                        >
                        <!-- </v-btn
                        > -->
                      </template>
                      <span>Discard</span>
                    </v-tooltip></span
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-btn icon :disabled="!props.item.is_extracted"> -->
                      <v-icon
                        :disabled="!props.item.is_extracted"
                        v-bind="attrs"
                        v-on="on"
                        @click="showPDFPreview(props.item)"
                        >mdi-eye-outline</v-icon
                      >
                      <!-- </v-btn> -->
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col class="remove_top_space" cols="4" v-if="showpreview">
          <v-card>
            <v-card-title class="preview_card"
              ><v-spacer></v-spacer><span style="margin-top: -7px">Preview</span
              ><v-spacer></v-spacer
              ><v-icon
                color="white"
                size="20"
                style="margin-right: 2%; margin-top: -7px"
                @click="showpreview = false"
                >mdi-window-close</v-icon
              ></v-card-title
            >
            <v-carousel height="78vh">
              <v-carousel-item
                class="image-item"
                v-for="(item, i) in img_url"
                :key="i"
                :src="item"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeadSection from "../components/HeadSection.vue";
import FormPreview from "../components/FormPreview.vue";
import PulseLoader from "../components/PulseLoader.vue";
import moment from "moment";
// import CancelDialog from '../components/CancelDialog.vue';
export default {
  name: "formQueue",
  components: {
    HeadSection,
    FormPreview,
    PulseLoader,
    // CancelDialog,
  },
  computed: {
    roleType() {
      if (sessionStorage.getItem("userRole") === "Admin") {
        this.active_tab = 2;
        return true;
      } else {
        this.active_tab = 1;
        return false;
      }
    },
    itemsWithFormSno() {
      return this.fileData.map((d, index) => ({
        ...d,
        sno: index + 1 + (this.pageNo - 1) * this.itemsOnPage,
      }));
    },
    computedHeaders() {
      if (this.isIntakeQueue) {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Created Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Uploaded By",
            value: "processed_by",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Source",
            value: "source",
            sortable: false,
            class: "font-ExtraBold ",
            width: "8%",
          },
          {
            text: "Associated Template",
            value: "associated_template",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Status",
            value: "known",
            sortable: true,
            class: "font-ExtraBold ",
            width: "16%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      } else if (this.isTemplateReview) {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "14%",
          },
          {
            text: "Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Processed By",
            value: "processed_by",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Source",
            value: "source",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Status",
            value: "known",
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      } else if (this.isUnassignedFormReview) {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Source",
            value: "source",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Associated Template",
            value: "associated_template",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Status",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      } else if (this.isAssignedFormReview) {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Processed By",
            value: "processed_by",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Source",
            value: "source",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Associated Template",
            value: "associated_template",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Status",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      } else if (this.isSubmittedQueue) {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Processed By",
            value: "processed_by",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Source",
            value: "source",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Associated Template",
            value: "associated_template",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Target Database",
            value: "target_database",
            sortable: false,
            class: "font-ExtraBold ",
            width: "10%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      } else {
        return (this.headers = [
          {
            text: "S. No.",
            value: "sno",
            class: "font-ExtraBold ",
            width: "6%",
          },
          {
            text: "Form Name",
            value: "filename",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Date/Time",
            value: "datetime",
            sortable: false,
            class: "font-ExtraBold ",
            width: "20%",
          },
          {
            text: "Completed By",
            value: "completed_by",
            sortable: false,
            class: "font-ExtraBold ",
            width: "12%",
          },
          {
            text: "Process",
            value: "process",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
          {
            text: "Actions",
            value: "known",
            sortable: false,
            class: "font-ExtraBold ",
            width: "15%",
          },
        ]);
      }
    },
  },
  data: () => ({
    headers: [],
    fileData: [],
    showpreview: false,
    isSelecting: false,
    isIntakeQueue: false,
    isTemplateReview: false,
    isAssignedFormReview: false,
    isUnassignedFormReview: true,
    isSubmittedQueue: false,
    isIllegibleQueue: false,
    extractDialog: false,
    loading: false,
    reviewData: [],
    img_url: [],
    showPage: true,
    itemsOnPage: 10,
    pageNo: 1,
    totalPage: 0,
    totalCount: 0,
    loader: false,
    inProcess: false,
    queueType: 0,
    active_tab: 2,
  }),
  methods: {
    downloadFile(url) {
      var link = document.createElement("a");
      link.href = url;
      link.click();
    },
    getDateTime() {
      this.fileData.forEach((e) => {
        e.datetime = moment(e.datetime.$date).format("DD-MMM-YYYY, hh:mm A");
      });
    },
    sendFormId(item) {
      item.is_processed = 1;
      let id = item._id.$oid;
      this.$router.push({ name: "formDiscovery", params: { id: id } });
    },
    async processingForm(item) {
      let params = {};
      params.id = item._id.$oid;
      params.is_processed = 5;
      params.completed_by = JSON.parse(
        sessionStorage.getItem("account")
      ).localAccountId;
      let response = await this.axios.post(
        process.env.VUE_APP_END_POINT + "/process-illegible",
        params,
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data.data == "Completed") {
        this.getData();
      }
    },
    formExtract(item) {
      let id = item._id.$oid;
      this.$router.push({ name: "extractionPage", params: { id: id } });
    },
    async changeInItems(event) {
      if (event != -1) this.itemsOnPage = event;
      else this.itemsOnPage = this.totalCount;
      await this.getData();
    },
    async changeInPage(event) {
      if (event) {
        this.pageNo = event;
      }
      await this.getData();
    },
    async getData() {
      if (this.$store.getters.queueType == "Review") {
        this.isIntakeQueue = false;
        this.isTemplateReview = true;
        this.queueType = 1;
        this.active_tab = 1;
      } else if (this.$store.getters.queueType == "Unassigned") {
        this.isIntakeQueue = false;
        this.isUnassignedFormReview = true;
        this.queueType = 2;
        this.active_tab = 2;
      } else if (this.$store.getters.queueType == "Assigned") {
        this.isIntakeQueue = false;
        this.isAssignedFormReview = true;
        this.queueType = 3;
        this.active_tab = 3;
      } else if (this.$store.getters.queueType == "Submitted") {
        this.isIntakeQueue = false;
        this.isSubmittedQueue = true;
        this.queueType = 4;
        this.active_tab = 4;
      } else if (this.$store.getters.queueType == "Illegible") {
        this.isIntakeQueue = false;
        this.isIllegibleQueue = true;
        this.queueType = 5;
        this.active_tab = 5;
      }
      this.fileData = [];
      this.loader = true;
      var params = {};
      params.queue_type = this.queueType;
      params.limit = this.itemsOnPage;
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      this.$store.getters.sessionTime = JSON.parse(
        sessionStorage.getItem("account")
      ).idTokenClaims.exp;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/form-queue",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data) {
        this.loader = false;
      }
      this.fileData = response.data.data;
      if (response.data.photo != "") {
        this.$store.state.photo = response.data.photo.slice(
          2,
          response.data.photo.length - 1
        );
      }
      this.getDateTime();
      this.totalCount = response.data.count;
      this.totalPage = Math.ceil(this.totalCount / this.itemsOnPage);
    },
    async getintakeQueue() {
      this.fileData = [];
      this.$store.getters.queueType = "Intake";
      this.isIntakeQueue = true;
      this.isTemplateReview = false;
      this.isUnassignedFormReview = false;
      this.isAssignedFormReview = false;
      this.isSubmittedQueue = false;
      this.isIllegibleQueue = false;
      this.queueType = 0;
      await this.changeInPage();
      if (this.fileData.some((q) => !q.is_extracted))
        setTimeout(() => {
          this.getintakeQueue();
        }, 15000);
    },
    async getTemplateReview() {
      this.fileData = [];
      this.$store.getters.queueType = "Review";
      this.isIntakeQueue = false;
      this.isTemplateReview = true;
      this.isUnassignedFormReview = false;
      this.isAssignedFormReview = false;
      this.isSubmittedQueue = false;
      this.isIllegibleQueue = false;
      this.queueType = 1;
      this.pageNo = 1;
      await this.changeInPage();
    },
    async getFormReviewUnassigned() {
      this.fileData = [];
      this.$store.getters.queueType = "Unassigned";
      this.isIntakeQueue = false;
      this.isTemplateReview = false;
      this.isUnassignedFormReview = true;
      this.isAssignedFormReview = false;
      this.isSubmittedQueue = false;
      this.isIllegibleQueue = false;
      this.queueType = 2;
      this.pageNo = 1;
      await this.changeInPage();
    },
    async getFormReviewAssigned() {
      this.fileData = [];
      this.$store.getters.queueType = "Assigned";
      this.isIntakeQueue = false;
      this.isTemplateReview = false;
      this.isUnassignedFormReview = false;
      this.isAssignedFormReview = true;
      this.isSubmittedQueue = false;
      this.isIllegibleQueue = false;
      this.queueType = 3;
      this.pageNo = 1;
      await this.changeInPage();
    },
    async getSubmittedQueue() {
      this.fileData = [];
      this.$store.getters.queueType = "Submitted";
      this.isIntakeQueue = false;
      this.isTemplateReview = false;
      this.isUnassignedFormReview = false;
      this.isAssignedFormReview = false;
      this.isSubmittedQueue = true;
      this.isIllegibleQueue = false;
      this.queueType = 4;
      this.pageNo = 1;
      await this.changeInPage();
    },
    async getIllegibleQueue() {
      this.fileData = [];
      this.$store.getters.queueType = "Illegible";
      this.isIntakeQueue = false;
      this.isTemplateReview = false;
      this.isUnassignedFormReview = false;
      this.isAssignedFormReview = false;
      this.isSubmittedQueue = false;
      this.isIllegibleQueue = true;
      this.queueType = 5;
      this.pageNo = 1;
      await this.changeInPage();
    },
    async onDelete(item) {
      swal({
        title: "Are you sure you want to delete this record?",
        text: "If you delete it, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
          const params = {};
          params.id = item._id.$oid;
          params.name = item.formname;
          let response = this.axios
            .delete(process.env.VUE_APP_END_POINT + "/form-queue", {
              params,
              headers: {
                accessToken: sessionStorage.accessToken,
              },
            })
            .then((res) => {
              if (res.data.data == "Deleted") {
                this.changeInPage();
              }
            });
        }
      });
    },
    oninput() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploadFile.click();
    },
    async onUpload() {
      this.loading = true;
      const input = this.$refs.uploadFile;
      const files = input.files;
      for (var i = 0; i < files.length; i++) {
        const formData = new FormData();
        let item = {};
        let params = {};
        params.uploaded_by = JSON.parse(
          sessionStorage.getItem("account")
        ).localAccountId;
        item.ID = this.fileData.length + 1;
        item.formname = files[i].name;
        item.isloading = true;
        item.uniqueId = files[i].name + i;
        let file = files[i];
        formData.append("file", file);
        this.axios
          .post(process.env.VUE_APP_END_POINT + "/form-queue", formData, {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          })
          .then((res) => {
            this.fileData.forEach((item) => {
              if (item.id == res.data.id) {
                item.is_extracted = 1;
                item.images_url = res.data.images_url;
                item.isloading = false;
              }
            });
            setTimeout(() => {
              this.getintakeQueue();
            }, 2000);
          })
          .catch((e) => {
            const errorItem = this.fileData.find(
              (q) => (q.uniqueId = item.uniqueId)
            );
          });
        this.fileData.unshift(item);
        this.totalCount = this.fileData.length;
      }
      setTimeout(() => {
        this.getintakeQueue();
      }, 10000);
    },
    async showPDFPreview(item) {
      this.img_url = item.images_url;
      this.showpreview = true;
    },
    verifyForm() {
      this.verifiedform = false;
      if (this.verifiedform) {
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped src="../assets/styles/css/form-queue.css"></style>
